.navbar {
  width: 100%;
  height: 100px;
  background-color: #3b0862; /*#000235; */
  display: flex;
  flex-direction: row;
}

.imageLogo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid slategray;
  border-radius: 8px;
}

.navbar .leftSide {
  flex: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 150px;
}

.navbar .leftSide img {
  width: 70px;
}

.navbar .rightSide {
  flex: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 10px;
}

.navbar .rightSide button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.navbar .rightSide .link:hover {
  background-color: darkslategray;
}

.navbar .rightSide svg {
  font-size: 30px;
}

.navbar #open {
  padding-left: 0px;
}
.navbar #open img {
  display: none;
}

.navbar #close img {
  display: inherit;
}

.navbar #open .hiddenLinks {
  display: inherit;
  /*margin-left: 5px;*/
}

.navbar #close .hiddenLinks {
  display: none;
}

.navbar #open a {
  width: auto;
  margin: 5px;
  justify-content: flex-end;
}
@media only screen and (max-width: 900px) {
  .navbar .rightSide a {
    width: auto;
  }
  .navbar .leftSide {
    padding-left: 20px;
  }
}

@media  (max-width: 600px) {
  .navbar .rightSide a {
    display: none;
  }

  .navbar .rightSide {
    justify-content: flex-end;
    padding-right: 20px;
  }

  .navbar .rightSide button {
    display: inherit;
  }

  .navbar .leftSide a {
    display: flex;
    justify-content: space-around;
    color: white;
    margin-right: 5px;
  }

}

@media only screen and (min-width: 600px) {
  .navbar .rightSide button {
    display: none;
  }

  .hiddenLinks {
    display: none;
  }

}
