* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
}

.main-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}


.item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: content-box;
  /*border: 1px ridge slategray;*/
  border-radius: 15px;
  padding: 5px;
  margin: 10px 20px;
  max-width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
.img-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  max-width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}


.detail-container {
 display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  text-align: center;
}


.header1 {
  margin: 5px;
  line-height: 1.9;
  word-spacing: 3px;
  letter-spacing: 2px;
  font-style: oblique;
  font-weight: 80;
}


.paragraph {
  margin: 10px;
  font-family: inherit;
  font-size: 20px;
  line-height: 1.5;
}

.text-center {
  text-align: start;
}
.service-main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: content-box;
  border: 1px ridge slategray;
  border-radius: 15px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin: 10px 30px 20px 30px;
  text-align: center;
}
.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 20px;
}
.home-service {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  flex-wrap: wrap;
}

.service-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;
  width: 300px;
  margin: 10px;
}

.service-background-image:hover {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(-3px);
}

@media (max-width: 500px) {
  .item-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: content-box;
    /*border: 1px ridge slategray;*/
    border-radius: 15px;
    margin: 5px;
    max-width: 100%;
  }

  .service-main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-sizing: content-box;
    border: 1px ridge slategray;
    border-radius: 15px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    margin: 5px;
    max-width: 100%;
  }
}