.main-contact-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.item-contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  box-sizing: content-box;
  /*border: 1px ridge slategray;*/
  border-radius: 15px;
  padding: 5px;
  margin: 10px 20px;
  max-width: 100%;
}

.detail-contact-container {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  text-align: center;
}

strong {
  font-size: large;
  font-family: inherit;
}


input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  font-style: italic;
}

textarea::placeholder {
  font-weight: bold;
  opacity: 0.5;
  font-style: italic;
}



#input-name,
#input-email {
  margin-bottom: 10px;
  width: 70%;
  height: 30px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

#input-phone {
  margin-bottom: 5px;
  width: 40%;
  height: 30px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

input[type="text"]:focus {
  background-color: lightblue;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  resize: vertical;
}

#button {
  background-color: #3b0862;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  height: 40px;
  color: floralwhite;
  border-radius: 8px;
  cursor: pointer;
}

label {
  margin-top: 5px;
  line-height: 24px;
  font-size: 18px;
}


.submit-message {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.submit-message .success {
  font-size: 15px;
  color: green;
  position: absolute;
  animation: buttons 0.3s linear;
  display: none;
}

.submit-message .danger {
  font-size: 15px;
  color: red;
  position: absolute;
  transition: 0.3s;
  animation: buttons 0.3s linear;
  display: none;
}

@keyframes buttons {
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
