.footer {
  width: 100%;

  /*background-color: #121619;*/
  /*background-color: #4e0e76;*/
  background-color: #3b0862;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.par-diff {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.socialMedia svg {
  color: white;
  margin: 20px;
  font-size: 50px;
  cursor: pointer;
}

.footer p {
  color: white;
  font-family: inherit;
  flex-wrap: wrap;
}

.footer-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid slategray;
  border-radius: 8px;
}