

.services-main-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.services-main-header {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: inherit;
}

.services-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.services-box {
    background-color: #ffffff;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    width: 85%;
}

.services-box:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(-3px);
}

.services-detail-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    padding: 10px;
    width: 100%;
}
.services-paragraph {
    text-align: start;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin: 10px;
    font-family: inherit;
    font-size: 19px;
    line-height: 1.5;
}