.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: inherit;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /*background-color: #f6f5f7;*/
  background-color: #ffffff;
  font-family: inherit;
}
