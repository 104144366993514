* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-family: 'Georgia', sans-serif;*/
}

.main-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.main-container-image {
    position: relative;
    background-image: url("/public/book-keep/only-logo.jpg");
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
}

.main-header {
    display: flex;
    margin: 10px;
    word-spacing: 5px;
    letter-spacing: 2px;
}

.about-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: content-box;
    border: 1px ridge slategray;
    border-radius: 15px;
    padding: 20px;
    margin: 30px 60px;
    position: relative;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.5);
}

.about-header {
    text-align: center;
    margin: 10px;
    line-height: 1.9;
    word-spacing: 10px;
    letter-spacing: 2px;
}


.about-detail-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    padding: 10px;
    width: 100%;
}



.about-paragraph {
    text-align: start;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin: 10px;
    font-family: inherit;
    font-size: 19px;
    line-height: 1.5;
}

@media (max-width: 500px) {
    .about-header {
        text-align: center;
        margin: 5px;
        line-height: 1.9;
        word-spacing: 10px;
        letter-spacing: 2px;
    }
    .about-container {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        box-sizing: content-box;
        border: 1px ridge slategray;
        border-radius: 15px;
        margin: 10px;
        position: relative;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.5);
    }
}

